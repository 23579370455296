<template>
  <div class="page-container tickets-page">
    <portal to="page-name">Ajuda e sugestões</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Ajuda e sugestões</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label class="form-label">Status</label>
            <select id="filter-status" class="form-select"
                    v-model="filter.status">
              <option value="">[Todos os status]</option>
              <option v-for="(text, value) in statuses"
                      :value="value" :key="value">{{ text }}</option>
            </select>
          </div>
          <div class="column col-3 col-md-12 col-sm-12 form-group">
            <label class="form-label">Setor</label>
            <select id="filter-sector" class="form-select"
                    v-model="filter.sector">
              <option value="">[Todos os setores]</option>
              <option v-for="(text, value) in sectors"
                      :value="value" :key="value">{{ text }}</option>
            </select>
          </div>
          <div class="column form-group">
            <label class="form-label">Pesquisar</label>
            <div class="input-group">
              <input type="text" class="form-input"
                     v-model="filter.search"
                     placeholder="Pesquisar solicitante, resumo..."
                     autocomplete="nope">
              <button class="btn btn-neutral btn-action input-group-btn btn-icon" tabindex="-1">
                <fa-icon :icon="['fal', 'search']"/>
              </button>
            </div>
          </div>
          <div class="column col-auto mb-2" style="display: flex; align-items: flex-end">
            <button class="btn btn-primary btn-icon btn-icon-left"
                    @click="create">
              <fa-icon :icon="['fal', 'plus']"/>Novo ticket
            </button>
          </div>
        </div>
        <div class="loading loading-lg mt-2" v-if="loading" />
        <template v-else>
          <div class="empty mt-2" v-if="data.items && data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Tickets</p>
            <p class="empty-subtitle">
              Nenhum ticket encontrado. Verifique os filtros acima.
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" style="width: 40px">#</th>
              <th>Data e hora</th>
              <th class="hide-sm">Departamento</th>
              <th class="hide-sm">Resumo</th>
              <th class="hide-sm">Solicitante</th>
              <th>Status</th>
              <th />
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items"
                class="c-hand" :key="i"
                @click="edit(item)">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td>{{ item.date | date('DD/MM/YYYY HH:mm') }}</td>
              <td class="hide-sm">{{ getSectorName(item.sector) }}</td>
              <td class="hide-sm">{{ item.summary }}</td>
              <td class="hide-sm">{{ item.user.name }}</td>
              <td>
                <span class="chip" :class="statusColor(item.status)">
                  {{ getStatusName(item.status) }}
                </span>
              </td>
              <td class="text-right">
                <fa-icon :icon="['fal', 'chevron-right']" />
              </td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer text-center" v-if="data.items.length > 0">
        <button
          v-if="data.hasMore && !loading"
          class="btn btn-primary btn-fixed-width"
          @click="loadMore"
          :disabled="loadingMore"
          :class="{ loading: loadingMore }"
        >Carregar mais</button>
      </div>
    </div>
    <dx-modal
      v-model="modalData.show"
      title="Ticket"
      size="lg"
      id="ticket-modal">
      <div class="columns">
        <div class="column col-6 form-group">
          <label class="form-label">Data e hora</label>
          <input type="text"
                 id="date"
                 class="form-input text-bold text-secondary"
                 readonly
                 :value="getDate"
          >
        </div>
        <div class="column col-6 form-group">
          <label class="form-label">Solicitante</label>
          <input type="text"
                 id="user"
                 class="form-input text-bold text-secondary"
                 readonly
                 :value="getUserName"
          >
        </div>
      </div>
      <div class="divider" />
      <div class="columns">
        <div class="column col-6 form-group"
             :class="{'has-error': $v.modalData.form.sector.$error}">
          <label class="form-label">Setor</label>
          <select id="sector"
                  class="form-select"
                  :disabled="isReadonly"
                  @blur="$v.modalData.form.sector.$touch()"
                  v-model="modalData.form.sector">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in sectors"
                    :value="value"
                    :key="value">
              {{ text }}
            </option>
          </select>
          <template v-if="$v.modalData.form.sector.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalData.form.sector.required">Campo obrigatório</div>
          </template>
        </div>
        <div class="column col-6 form-group"
             :class="{'has-error': $v.modalData.form.status.$error}">
          <label class="form-label">Status</label>
          <select id="status"
                  class="form-select"
                  disabled
                  @blur="$v.modalData.form.status.$touch()"
                  v-model="modalData.form.status">
            <option value="">[Selecione]</option>
            <option v-for="(text, value) in statuses"
                    :value="value"
                    :key="value">
              {{ text }}
            </option>
          </select>
          <template v-if="$v.modalData.form.status.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalData.form.status.required">Campo obrigatório</div>
          </template>
        </div>
        <div class="column col-12 form-group"
             :class="{'has-error': $v.modalData.form.summary.$error}">
          <label class="form-label">Resumo</label>
          <input type="text"
                 id="summary"
                 class="form-input"
                 maxlength="100"
                 :disabled="isReadonly"
                 @blur="$v.modalData.form.summary.$touch()"
                 v-model="modalData.form.summary"
          >
          <template v-if="$v.modalData.form.summary.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalData.form.summary.required">Campo obrigatório</div>
          </template>
        </div>
        <div class="column col-12 form-group"
             :class="{'has-error': $v.modalData.form.description.$error}">
          <label class="form-label">Descrição</label>
          <textarea id="description"
                    class="form-input"
                    :disabled="isReadonly"
                    @blur="$v.modalData.form.description.$touch()"
                    v-model=" modalData.form.description"
                    rows="3"
          />
          <template v-if="$v.modalData.form.description.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalData.form.description.required">Campo obrigatório</div>
          </template>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary mr-1"
                :class="{loading: modalData.saving}"
                :disabled="modalData.saving"
                v-if="!isReadonly"
                @click="save">
          Salvar
        </button>
        <button class="btn btn-error mr-1"
                :class="{loading: modalData.deleting}"
                :disabled="modalData.deleting"
                v-if="isReadonly && modalData.form.status === 'open'"
                @click="remove">
          Excluir
        </button>
        <button class="btn"
                @click="modalData.show = false">
          Sair
        </button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import formMixin from 'src/mixins/form';
import { sectors, getName as getSectorName } from '@/data/ticket-sectors';
import { statuses, getName as getStatusName } from '@/data/ticket-statuses';
import { required } from 'vuelidate/lib/validators';
import { mergeFrom } from '@/helpers/object';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/tickets',
      filter: {
        status: '',
        sector: '',
        search: '',
      },
      sectors,
      statuses,
      getSectorName,
      getStatusName,
      debounceTimeout: null,
      loading: false,
      loadingMore: false,
      data: {
        items: [],
        hasMore: false,
        limit: 30,
        offset: 0,
      },
      modalData: {
        show: false,
        saving: false,
        deleting: false,
        form: this.blankForm(),
      },
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.reload, 1000);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    isReadonly() {
      return !!this.modalData.form.id;
    },
    getDate() {
      if (this.modalData.form.id) {
        return moment(this.modalData.form.date).format('DD/MM/YYYY HH:mm');
      }
      return moment().format('DD/MM/YYYY HH:mm');
    },
    getUserName() {
      if (this.modalData.form.id) {
        return this.modalData.form.user.name;
      }
      return this.user.name;
    },
  },
  validations() {
    return {
      modalData: {
        form: {
          sector: { required },
          status: { required },
          summary: { required },
          description: { required },
        },
      },
    };
  },
  methods: {
    async reload() {
      this.data.offset = 0;
      await this.load();
    },
    async loadMore() {
      this.data.offset += this.data.limit;
      await this.load();
    },
    load() {
      const firstData = this.data.offset === 0;

      if (firstData) {
        this.loading = true;
      } else {
        this.loadingMore = true;
      }

      const params = {
        limit: this.data.limit,
        offset: this.data.offset,
      };

      if (this.filter.status) {
        params.status = this.filter.status;
      }

      if (this.filter.sector) {
        params.sector = this.filter.sector;
      }

      if (this.filter.search.trim().length > 0) {
        params.search = this.filter.search;
      }

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data.hasMore = data.hasMore;
          this.data.items = firstData ? data.items : [...this.data.items, ...data.items];
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.loadingMore = false;
        });
    },
    create() {
      this.$v.modalData.form.$reset();
      this.modalData.form = this.blankForm();
      this.modalData.show = true;
    },
    edit(data) {
      this.modalData.form = mergeFrom(this.blankForm(), data);
      this.modalData.show = true;
    },
    save() {
      this.$v.modalData.form.$touch();
      if (this.$v.modalData.form.$error) {
        return null;
      }

      this.modalData.saving = true;

      const data = this.clone(this.modalData.form);

      return this.$http
        .post(this.path, data)
        .then(({ data: response }) => {
          this.modalData.show = false;
          this.data.items.unshift(response);
          this.$toast.show('Registro salvo!');
        })
        .catch(() => {})
        .finally(() => {
          this.modalData.saving = false;
        });
    },
    remove() {
      this.modalData.deleting = true;
      return this.$http
        .delete(`${this.path}/${this.modalData.form.id}`)
        .then(() => {
          const found = this.data.items.find(({ id }) => id === this.modalData.form.id);
          const idx = this.data.items.indexOf(found);
          this.data.items.splice(idx, 1);
          this.modalData.show = false;
          this.$toast.show('Registro excluído!');
        })
        .catch(() => {})
        .finally(() => {
          this.modalData.deleting = false;
        });
    },
    statusColor(status) {
      if (status === 'open') {
        return 'bg-info';
      } if (status === 'review') {
        return 'bg-warning';
      } if (status === 'finished') {
        return 'bg-success text-dark';
      }
      return 'bg-error text-light';
    },
    blankForm() {
      return {
        id: '',
        date: '',
        sector: '',
        status: 'open',
        user: {
          id: '',
          name: '',
        },
        summary: '',
        description: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.tickets-page {
  .chip {
    justify-content: center;
    width: 75px;
  }
  .btn-fixed-width {
    font-size: $font-size-lg;
    height: 2.2rem;
    margin: $layout-spacing-lg 0;
    width: 11rem;
  }
}
</style>
