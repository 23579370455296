export const statuses = {
  open: 'Pendente',
  review: 'Em análise',
  finished: 'Finalizado',
  canceled: 'Cancelado',
};

export function getName(value) {
  return statuses[value];
}
